import { PayloadAction, createSlice } from "@reduxjs/toolkit"



export const currencySlice = createSlice({
  name: 'cartSaree',
  initialState:{currency:"INR"},
  reducers: {
    changeCurrency: (state,action:PayloadAction<string>) => {
      const currency = action.payload
        state.currency= currency
    },
  },
})

export const { changeCurrency } = currencySlice.actions


export default currencySlice.reducer