import { useState } from "react";
import { CALENDLYURL } from "../constants/appConfig"
import { InlineWidget, useCalendlyEventListener } from "react-calendly"


const ExploreVideo = () => {
  const [state, setState] = useState<any>(null); // Adjust the type as per your requirements
  useCalendlyEventListener({
    onEventScheduled: (e) => setState(e.data.payload),
  });
  console.log(state);
  
  return (
    <div className="bgcolorgold overflow-hidden">
         <InlineWidget url={CALENDLYURL} />
    </div>
  )
}

export default ExploreVideo