import ForgotPasswordC from "../components/shared/auth/ForgotPasswordC"
import { Card } from "../components/ui/card"



const ForgotPwd = () => {
  return (<section className="bgcolorgold">
    <div className="wrapper flex-center bgcolorgold">
      <Card className="shadow-xl border-2 border-gray-300  min-w-[350px] md:min-w-[450px] p-4">
      <h1 className="text-xl font-bold text-center">Forgot Password</h1>
    <ForgotPasswordC/>
    </Card>
    </div>
    </section>)
}

export default ForgotPwd