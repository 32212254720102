import { apiSlice } from "../apiSlice";


export const categoryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllCategory: builder.query({
      query: () => ({ url: "/common/getAllCategory" }),
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Category", id: "LIST" },
            ...result.ids.map((id: any) => ({ type: "Category", id })),
          ];
        } else return [{ type: "Category", id: "LIST" }];
      },
    }),
    getSubcategory: builder.query({
      query: (id) => ({ url: `/common/getSubcategory/${id}` }),
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "SubCategory", id: "LIST" },
            ...result.ids.map((id: any) => ({ type: "SubCategory", id })),
          ];
        } else return [{ type: "SubCategory", id: "LIST" }];
      },
    }),
    getAllSubcategory: builder.query({
      query: (id) => ({ url: `/common/getAllSubcategory` }),
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "SubCategory", id: "LIST" },
            ...result.ids.map((id: any) => ({ type: "SubCategory", id })),
          ];
        } else return [{ type: "SubCategory", id: "LIST" }];
      },
    }),
    getAllColors: builder.query({
      query: () => ({ url: "/common/getAllColors" }),
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Color", id: "LIST" },
            ...result.ids.map((id: any) => ({ type: "Color", id })),
          ];
        } else return [{ type: "Color", id: "LIST" }];
      },
    }),
  }),
});


export const {
    useGetAllCategoryQuery,
    useGetSubcategoryQuery,
    useGetAllSubcategoryQuery,
    useGetAllColorsQuery
} = categoryApiSlice

