
import SignUpComponent from "../components/shared/auth/SignUpComponent."
import { Card } from "../components/ui/card"


const SIgnUp = () => {
  return (<section className="bgcolorgold">
    <div className="wrapper flex-center ">
      <Card className="shadow-xl border-2 border-gray-300  min-w-[350px] md:min-w-[500px] p-4">
      <h1 className="text-xl font-bold text-center">Register</h1>
    <SignUpComponent/>
    </Card>
    </div>
    </section>
  )
}

export default SIgnUp

