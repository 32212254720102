import { apiSlice } from "../../../redux/apiSlice";


export const AproductsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        addNewProduct: builder.mutation({
            query: initialProdData => ({
                url: '/admin/saveProduct',
                method: 'POST',
                body: {
                    ...initialProdData,
                }
            }),
            invalidatesTags: [
                { type: 'Product', id: "LIST" }
            ]
        }),
        updateProduct: builder.mutation({
            query: initialProdData => ({
                url: '/admin/updateProduct',
                method: 'POST',
                body: {
                    ...initialProdData,
                }
            }),
            invalidatesTags: [
                { type: 'Product', id: "LIST" }
            ]
        }),
        deleteProductA: builder.mutation({
            query: ( id ) => ({
                url: `/admin/deleteProduct/${id}`,
                method: 'DELETE',
                }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Product', id: arg.id }
            ]
        }),
    }),
})

export const {
    useAddNewProductMutation,
    useDeleteProductAMutation,
    useUpdateProductMutation
} = AproductsApiSlice

