import { apiSlice } from "../apiSlice";


export const ACustomerAddressApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        saveCustomerAddress: builder.mutation({
            query: initialAddress => ({
                url: '/common/saveCustomerAddress',
                method: 'POST',
                body: {
                    ...initialAddress,
                }
            }),
            invalidatesTags: [
                { type: 'Address', id: "LIST" }
            ]
        }),
        deleteCustomerAddress: builder.mutation({
            query: ( id ) => ({
                url: `/common/deleteCustomerAddress/${id}`,
                method: 'DELETE',
                }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Address', id: arg.id }
            ]
        }),
    }),
})

export const {
    useSaveCustomerAddressMutation,
    useDeleteCustomerAddressMutation
} = ACustomerAddressApiSlice

