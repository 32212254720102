import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { Button } from "../../ui/button"
import {Form,FormControl,FormField,FormItem,FormLabel,FormMessage,} from "../../ui/form"
import { Input } from "../../ui/input"
import { useState } from "react"
import ReCAPTCHA from 'react-google-recaptcha';
import {  RECAPCHASITEKEY } from "../../../constants/appConfig"
import { Link, useNavigate } from "react-router-dom"
import {  useUserForgotPasswordMutation } from "../../../redux/slice/UserApiSlice"

const formSchema = z.object({
  email: z.string().email({
    message: 'Invalid email address.',
  }),
});




const ForgotPasswordC = () => {
  const [isVerified, setIsVerified] = useState(false);
  const [error, setError] = useState(false);
  const [succ, setSucc] = useState<string>("");
  const [userForgotPassword] = useUserForgotPasswordMutation()

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
    },
  });

  const handleVerify = () => {
    setIsVerified(true);
  };

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      if (isVerified) {
        const response = await userForgotPassword({ email: values.email });
        
        if ('error' in response) {
          console.error('error:', response.error);
          setError(true);
          return;
        }
  
        const { success, result } = response?.data;
  
        if (success && result) {
          setSucc("Password has sent to your Email")
          setError(false);
        } else{
          setError(true)
          setSucc("");}    
      } else {
        console.error('reCAPTCHA verification failed.');
      }
    } catch (error) {
      console.log(error);
    }
  };  

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8 my-4">
        {error&& <p className="text-red-500 font-bold my-2">No Account Registered On This EMail</p>}
        {succ&& <p className="text-green-500 font-bold my-2">{succ}</p>}
        <FormField
          control={form.control}
          name="email"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input placeholder="Email Address" {...field} className="input-field" />
              </FormControl>
              <FormMessage>{fieldState?.error?.message}</FormMessage>
            </FormItem>
          )}
        />
        <ReCAPTCHA
            sitekey={RECAPCHASITEKEY}
            size="normal"
            onChange={handleVerify} 
          />
        <Button type="submit" className="w-full headermenu" disabled={!form.formState.isValid || !isVerified}>
          Send Email
        </Button>
        <div className="flex justify-between">
           {succ&& <> <p>Email sent to you</p>
            <Link to="/signIn" className="text-red-400 font-semibold">Go to SignIn</Link></>}
           {error&& <> <p>Create New Account</p>
            <Link to="/signup" className="text-red-400 font-semibold">Register</Link></>}
        </div>
      </form>
    </Form>
  );
};


export default ForgotPasswordC