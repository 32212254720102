
import { apiSlice } from "../apiSlice";


export const CartApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getShoppingCartByCustomer: builder.query({
            query: (id) =>({url:`/common/getShoppingCartByCustomer/${id}`,}),
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                  return [
                    { type: 'Cart', id: 'LIST' },
                    ...result.ids.map((id:any) => ({ type: 'Cart', id })),
                  ];
                } else return [{ type: 'Cart', id: 'LIST' }];
              },
        }),
        addItemCart: builder.mutation({
            query: initialCartData => ({
                url: '/common/addItemCart',
                method: 'POST',
                body: {
                    ...initialCartData,
                }
            }),
            invalidatesTags: [
                { type: 'Cart', id: "LIST" }
            ]
        }),
        updateShoppingCartItem: builder.mutation({
            query: initialCartData => ({
                url: '/common/updateShoppingCartItem',
                method: 'POST',
                body: {
                    ...initialCartData,
                }
            }),
            invalidatesTags: [
                { type: 'Cart', id: "LIST" }
            ]
        }),
        deleteItemCart: builder.mutation({
            query: ( id ) => ({
                url: `/common/deleteItemCart/${id}`,
                method: 'DELETE',
                }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Cart', id: arg.id }
            ]
        }),
        getCartCountByCustomer: builder.query({
            query: (id) =>({url:`/common/getCartCountByCustomer/${id}`}),
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                  return [
                    { type: 'Cart', id: 'LIST' },
                    ...result.ids.map((id:any) => ({ type: 'Cart', id })),
                  ];
                } else return [{ type: 'Cart', id: 'LIST' }];
              },
        }),
    }),
})

export const {
    useGetShoppingCartByCustomerQuery,
    useAddItemCartMutation,
    useDeleteItemCartMutation,
    useGetCartCountByCustomerQuery,
    useUpdateShoppingCartItemMutation
} = CartApiSlice

