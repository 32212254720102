import SignInComponent from "../components/shared/auth/SignInComponent"
import { Card } from "../components/ui/card"



const SignIn = () => {
  return (<section className="bgcolorgold">
    <div className="wrapper flex-center bgcolorgold">
      <Card className="shadow-xl border-2 border-gray-300  min-w-[350px] md:min-w-[450px] p-4">
      <h1 className="text-xl font-bold text-center">Login In</h1>
    <SignInComponent/>
    </Card>
    </div>
    </section>)
}

export default SignIn