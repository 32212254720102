
import { ProductWithImages } from "@/react-app-env"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"




type whishlistType={
    wishlist:ProductWithImages[]
}

const initialState:whishlistType = {
  wishlist:[]
}

export const whishlistSlice = createSlice({
  name: 'carProductWithImages',
  initialState,
  reducers: {
    addSareeToWhishlist: (state,action:PayloadAction<ProductWithImages>) => {
      const saree = action.payload
      const existingCartItem = state.wishlist.filter(item=>item.id===saree.id)
      if(!existingCartItem.length){
        state.wishlist.push({...saree})
      } 
    },
    removesareeFromWhishlist: (state, action: PayloadAction<number>) => {
        const itemIdToRemove = action.payload;
        state.wishlist = state.wishlist.filter((item) => item.id !== itemIdToRemove);
    },
    
  },
})

export const { addSareeToWhishlist,removesareeFromWhishlist } = whishlistSlice.actions


export default whishlistSlice.reducer