import { Dispatch, SetStateAction } from "react"
import { IMGURL } from "../../../constants/appConfig"
import { TImages } from "@/react-app-env"

type imageColProps={
  setcount:Dispatch<SetStateAction<number>>,
  ImgCk:Dispatch<SetStateAction<boolean>>,
  images:TImages[]
}

const ImagesCol = ({setcount,images,ImgCk}:imageColProps) => {
  
  return (
    <div className="flex md:flex-col flex-row  gap-3">
      {images.map((image,idx)=>{
       return  <button  key={image.id} onClick={()=>{setcount(image.id);ImgCk(true) }}><img
         src={`${IMGURL}${image.imageUrl}`}
         alt={"imageurl"}
         className="rounded-md bg-cover w-[65px] h-[70px]"
       /></button>
      })}
    </div>
  )
}

export default ImagesCol