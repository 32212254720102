
import { apiSlice } from "../apiSlice";


export const userApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        register: builder.mutation({
            query: credentials => ({
                url: '/user/registerCustomer',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        login: builder.mutation({
            query: credentials => ({
                url: '/user/authenticate',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        getCustomer: builder.query({
            query: (id) =>({url:`/common/getCustomer/${id}`}),
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                  return [
                    { type: 'User', id: 'LIST' },
                    ...result.ids.map((id:any) => ({ type: 'User', id })),
                  ];
                } else return [{ type: 'User', id: 'LIST' }];
              },
        }),
        getAllCustomer: builder.query({
            query: (id) =>({url:`/common/getAllCustomer`}),
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                  return [
                    { type: 'User', id: 'LIST' },
                    ...result.ids.map((id:any) => ({ type: 'User', id })),
                  ];
                } else return [{ type: 'User', id: 'LIST' }];
              },
        }),
        saveCustomer: builder.mutation({
            query: initialUserData => ({
                url: '/common/saveCustomer',
                method: 'POST',
                body: {
                    ...initialUserData,
                }
            }),
            invalidatesTags: [
                { type: 'User', id: "LIST" }
            ]
        }),
        userChangePassword: builder.mutation({
            query: updateUserData => ({
                url: `/user/userChangePassword/${updateUserData.id}`,
                method: 'PUT',
                body: {...updateUserData,}
            }),
            invalidatesTags: [
                { type: 'User', id: "LIST" }
            ]
        }),
        userForgotPassword: builder.mutation({
            query: updateUserData => ({
                url: `/user/userForgotPassword`,
                method: 'POST',
                body: {...updateUserData,}
            }),
            invalidatesTags: [
                { type: 'User', id: "LIST" }
            ]
        }),
    }),
})

export const {
    useRegisterMutation,
    useGetCustomerQuery,
    useLoginMutation,
    useSaveCustomerMutation,
    useGetAllCustomerQuery,
    useUserChangePasswordMutation,
    useUserForgotPasswordMutation
} = userApiSlice

