import React, { useEffect } from 'react'
import {  useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '@/redux/store'
import FSTableData from '../components/shared/Table/FSTableData'
import { useGetProductsByPriceMutation } from '../redux/slice/ProductsApiSlice'
import { useGetAllCategoryQuery } from '../redux/slice/CategoryApiSlice'





const SareesByPrice = () => {
  const [productByPrice, {isLoading,isSuccess,isError,error,data:products }] = useGetProductsByPriceMutation()
  const{data:categories}=useGetAllCategoryQuery("categoriesList",{pollingInterval:60000,refetchOnFocus:true,refetchOnMountOrArgChange:true})
  const { currency } = useSelector((state: RootState) => state.counterCurrency);
  const { price } = useParams();
  
  
  useEffect(()=>{
    productByPrice({currency,price,pageNumber:0})
  },[productByPrice,currency,price])


  
  let content
  if (isLoading) {
    content = (
        <div className="flex justify-center items-center h-full">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
        </div>
    );
}
  if (isError) content = <p>{products?.result?.message}</p>
  if (!products?.result?.length){
    content= <section className="bgcolorgold" >
      <p className="p-4 text-center text-lg font-semibold">
        No Sarees Available Under {price} {currency === 'USD' ? 'USD' : 'INR'}
      </p></section>}
  if (isSuccess&& products?.result?.length>0){
    content=  <FSTableData data={products?.result} categories={categories?.result} />
  }

  return (<section className="bgcolorgold" >
    <div>
     {content}
    </div>
    </section>);
};

export default SareesByPrice;

