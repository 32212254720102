import HeaderLayout from "../../components/shared/admin/layout/HeaderLayout";
import { Outlet } from "react-router-dom";


/* const DashboardLayout = () => {
  return (
    <div className="flex h-screen  flex-col md:flex-row">
      <div className="w-full flex-none md:w-56 mr-4">
        <HeaderLayout />
      </div>
      <div className="flex-grow p-6 md:p-12">
        <Outlet />
      </div>
    </div>
  );
}; */


//  const DashboardLayout = () => {
//   return (
//     <div className="flex flex-col md:flex-row">
//       <div className="md:w-56  md:overflow-hidden">
//         <div className="md:overflow-hidden md:w-56 md:fixed md:h-screen">
//           <HeaderLayout />
//         </div>
//       </div>
//       {/* <div className="flex-grow md:overflow-x-auto p-4 md:p-12"> */}
//         <Outlet />
//     </div>
//   );
// };


const DashboardLayout = () => {
  return (
    <div className="flex flex-col md:flex-row">
      <div className="md:w-56  md:overflow-hidden">
        <div className="md:overflow-hidden md:w-56 md:fixed md:h-screen">
          <HeaderLayout />
        </div>
      </div>
      <div className="w-full">
        <Outlet />
        </div>
    </div>
  );
};

export default DashboardLayout;

